
import detectEthereumProvider from "@metamask/detect-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";

const BN = Web3.utils.BN;
window.BN = BN;

const coins = require("./contracts/coins");
let idoConfig = coins.ido;

import ErrorNotice from "./component/ErrorNotice";

const chainMap = {
  "0x1": "Ethereum Main Network (Mainnet)",
  "0x3": "Ropsten Test Network",
  "0x4": "Rinkeby Test Network",
  "0x5": "Goerli Test Network",
  "0x2a": "Kovan Test Network",
};

export default {
  props: {
    contractType: null
  },
  components: {
    ErrorNotice,
  },
  data() {
    return {
      headerShow: false,
      coins,
      idoCoin: null,
      w3: null,
      account: "",
      provider: null,
      chain: "",
      loading: {
        ido: false
      },
      errorMsg: {
        ido: [],
      },
      balance: '',
      paused: true,
    };
  },
  async mounted() {
    const contractType = this.contractType;
    idoConfig = coins[contractType]
    await this.initMetaMask();
  },
  methods: {
    logout() {
      window.localStorage.clear()
      window.location.reload()
    },
    async startApp() {
      if (!this.account) {
        return
      }
      this.initContracts();
      this.getBalance();
    },
    async initWalletConnect() {
      const provider = new WalletConnectProvider({
        rpc: {
          1: "https://mainnet.infura.io/v3/ec6094d36cd1499986fb36a3e9cfc84a",
          // 2: "https://ropsten.infura.io/v3/ec6094d36cd1499986fb36a3e9cfc84a"
        },
        
      });
      await provider.enable();
      this.w3 = new Web3(provider);
      //  Get Accounts
      const accounts = await this.w3.eth.getAccounts();
      if (!accounts) {
        return
      }
      this.account = accounts[0]
      this.startApp()
      //  Get Chain Id
      // const chainId = await this.w3.eth.chainId();
      // this.chain = chainMap[chainId]

    },
    showAccount(val) {
      return `${val.slice(0, 4)}...${val.slice(-4)}`;
    },
    getNum(val) {
      if (val === "") {
        return 0;
      }
      if (typeof val === "boolean") {
        return val.toString();
      }
      return val / Math.pow(10, 18);
    },
    initContracts() {
      this.idoCoin = new this.w3.eth.Contract(idoConfig.abi, idoConfig.address);
    },
    async getBalance() {
      const pausedRes = await this.idoCoin.methods.paused().call().then(res => {
        console.log(res)
        this.paused = res
        return true
      }).catch(e => {
        console.log(e)
        if (e.message) {
          this.$Dialog({ message: 'Please check your wallet network.' });
        }
      })
      if (!pausedRes) {
        return
      }
      this.idoCoin.methods.balanceOf(this.account).call().then(res => {
        console.log(res)
        this.balance = res
      }).catch(e => {
        console.log(e)
      })
      
    },
    claim() {
      this.loading.ido = true
      this.idoCoin.methods.claim().send({
        from: this.account
      }).then(res => {
        Toast.success("Claim success!")
        this.getBalance()
      }).catch(e => {
        this.errorMsg.ido.push({
            message: e.message,
            transactionHash: e.receipt && e.receipt.transactionHash,
          });
      }).finally(() => {
        this.loading.ido = false
      })
    },
    async getAccounts() {
      const accounts = await window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((accounts) => {
          return accounts;
        })
        .catch((error) => {
          if (error.code === 4001) {
            // EIP-1193 userRejectedRequest error
            console.log("Please connect to MetaMask.");
          } else {
            console.error(error);
          }
        });
        if (!accounts) {
          return
        }
        window.ethereum.on("accountsChanged", (accounts) => {
          window.location.reload();
        });
        this.account = accounts[0];
      
    },
    async initMetaMask() {
      const provider = await detectEthereumProvider();
      if (provider) {
        console.log("Ethereum successfully detected!");
        this.w3 = new Web3(window.ethereum);
        // From now on, this should always be true:
        // provider === window.ethereum
        // Access the decentralized web!
        // Legacy providers may only have ethereum.sendAsync
        const chainId = await provider.request({
          method: "eth_chainId",
        });
        
        this.chain = chainMap[chainId];
        ethereum.on("chainChanged", (chainId) => {
          window.location.reload();
        });
        await this.getAccounts();
        this.startApp()
      } else {
        // Dialog.alert({
        //   message: "Please install MetaMask!",
        // });
        // if the provider is not detected, detectEthereumProvider resolves to null
        console.error("Please install MetaMask!", error);
      }
    },
  },
};
